import React, { useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";

export const LoadingButton = ({ loading, children, ...props }) => {
  const buttonRef = React.useRef(null);
  const [width, setWidth] = useState(null);

  const updateWidth = () => {
    if (buttonRef.current && buttonRef.current.offsetParent !== null) {
      setWidth(buttonRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateWidth();
    const observer = new ResizeObserver(updateWidth);
    if (buttonRef.current) {
      observer.observe(buttonRef.current);
    }
    return () => {
      if (buttonRef.current) {
        observer.unobserve(buttonRef.current);
      }
    };
  }, []);

  const circularProgressSize = {
    grown: 24,
    shrinked: 24,
    large: 23,
    small: 10,
    micro: 8,
    default: 24,
  }[props.size || "default"];

  return (
    <Button
      ref={buttonRef}
      disabled={loading}
      {...props}
      sx={{ ...props.sx, width }}
    >
      {loading ? (
        <CircularProgress size={circularProgressSize} color="inherit" />
      ) : (
        children
      )}
    </Button>
  );
};
