import { useContext, useCallback } from "react";
import { Box, Typography, Icon, IconButton, Tooltip } from "@mui/material";
import { ContactSupportOutlined } from "@mui/icons-material";
import { AuthContext } from "src/utils/AuthContext.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
// import ShareDialog from "src/components/blocks/dialogs/ShareDialog.js"
import { useCheckSubscriptionStatus, copyCalendarLinkToClipboard } from 'src/utils/Utils.js';
import { useTheme } from "@emotion/react";

const CalendarAddedSuccessfully = ({ sourceCalendar, sx }) => {
    const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();
    const { user, refetchAuth } = useContext(AuthContext);
    const { inGroup, subscribed } = useCheckSubscriptionStatus({ user, calendar: sourceCalendar });
    const copyToClipboard = useCallback(() => {
        copyCalendarLinkToClipboard({ inGroup, user, calendar: sourceCalendar, showSnackBar, setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity })
    }, [inGroup, sourceCalendar, setSnackbarMessage, setSnackbarOpen, setSnackbarSeverity, showSnackBar, user]);
    const theme = useTheme();

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            mt: 3,
            gap: 1,
            ...sx,
        }}
            data-testid="calendar-added-successfully"
        >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <Icon baseClassName="material-symbols-outlined" sx={{ fontWeight: "300", fontSize: "1.8rem", color: "success.main" }}>check</Icon>
                    <Typography variant="h2">Finish subscribing in your calendar.</Typography>
                </Box>
                <Tooltip title="Support">
                    <IconButton
                        component="a"
                        href={`https://stanzacal.substack.com/p/troubleshooting`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <ContactSupportOutlined fontSize="small" sx={{ color: `${theme.palette.text.secondary}` }} />
                    </IconButton>
                </Tooltip>
                {/*  <ShareDialog calendar={lastCalendarAdded} handle={lastCalendarAdded.handle} as="Button-Small" /> */}
            </Box>
            <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>You should be redirected to your calendar app. If not, {' '}<span onClick={copyToClipboard} style={{ textDecoration: 'underline', cursor: 'pointer' }}>copy this link</span> and import into your calendar.</Typography>
        </Box >
    )
}

export default CalendarAddedSuccessfully;