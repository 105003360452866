import React, {
  useState,
  useEffect,
  useContext,
  memo,
  useCallback,
  useMemo,
} from "react";
import axios from "axios";
import {
  Button,
  IconButton,
  Stack,
  Box,
  Icon,
  Tooltip,
  Typography,
  Link,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import NewGroupDialog from "./dialogs/NewGroupDialog.js";
import AddToGroupDialog from "./dialogs/AddToGroupDialog.js";
import {
  getPageRoute,
  getApiRoute,
  useGetUserCalendars,
  useSubscribeCalendar,
  useUnsubscribeCalendar,
  useRemoveFromGroup,
  useSubscribeGroup,
  useUnsubscribeGroup,
} from "src/services";
import { AuthContext } from "src/utils/AuthContext.js";
import ConsumerMailingListDialog from "./dialogs/ConsumerMailingListDialog.js";
import {
  redirectSignedOutUser,
  useCheckSubscriptionStatus,
  getWebcalFormat,
  copyCalendarLinkToClipboard,
  handleUnsubscribe,
  handleOpenWebcal,
} from "src/utils/Utils.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import SignInDialog from "src/components/blocks/dialogs/SignInDialog.js";
import { useDimensions } from "src/utils/useDimensions.js";
import AddToCalendarDialog from "./dialogs/AddToCalendarDialog.js";
import ShareDialog from "src/components/blocks/dialogs/ShareDialog.js";
import TimelineDialog from "./dialogs/TimelineDialog.js";
import { useTheme } from "@emotion/react";
import { flushSync } from "react-dom";
import RedirectionMessage from "./RedirectionMessage.js";
import { useGroupDialogs } from "src/utils/useGroupDialogs.js";
import { LoadingButton } from "./LoadingButton.js";
import { useMutation } from "@tanstack/react-query";

function SubscribeButton({
  creator,
  calendar,
  onSubscribeAction,
  isEventCard,
  setSelectedCalendar,
  eventId,
}) {
  const { mutateAsync: subscribeCalendar } = useSubscribeCalendar();
  const { mutateAsync: unsubscribeCalendar } = useUnsubscribeCalendar();
  const { mutateAsync: removeFromGroup } = useRemoveFromGroup();
  const { mutateAsync: subscribeGroup } = useSubscribeGroup();
  const { mutateAsync: unsubscribeGroup } = useUnsubscribeGroup();
  const [createdGroups, setCreatedGroups] = useState([]);
  const navigate = useNavigate();
  const [openSignInDialog, setOpenSignInDialog] = useState(false);
  const [openAddToCalendarDialog, setOpenAddToCalendarDialog] = useState(false);
  const [openTimelineDialog, setOpenTimelineDialog] = useState(false);
  const [showMailingListDialog, setShowMailingListDialog] = useState(false);
  const { user, refetchAuth } = useContext(AuthContext);
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();
  const typeGroup = calendar?.calendars;
  const calendarApiUrl = `${calendar?.stanzaLink}/${user?.id}.ics`;
  const { isSmallScreen } = useDimensions();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const { inGroup, subscribed, setSubscribed } = useCheckSubscriptionStatus({ user, calendar });
  const { data: userCalendars, refetch: refetchUserCalendars } =
    useGetUserCalendars({
      user,
    });
  const {
    openAddToGroup,
    openNewGroup,
    setOpenAddToGroup,
    setOpenNewGroup,
    handleGroupDialog,
    handleAddToGroup,
    handleCreateGroup,
    handleMessage,
    targetCalendar,
  } = useGroupDialogs({
    setCreatedGroups,
    onSubscribeAction,
    subscribed,
  });

  useEffect(() => {
    if (subscribed) {
      setOpenTimelineDialog(false);
    }
    const handleShowTimelineDialog = () => {
      const isTimelinePage = window.location.pathname.includes(
        `${calendar?.handle}`
      );
      if (isTimelinePage && !isEventCard) {
        if (
          window.scrollY > window.innerHeight * 0.3 &&
          !openTimelineDialog &&
          !subscribed
        ) {
          setOpenTimelineDialog(true);
        } else if (
          window.scrollY < window.innerHeight * 0.3 &&
          openTimelineDialog &&
          !subscribed
        ) {
          setOpenTimelineDialog(false);
        }
      }
      window.addEventListener("scroll", handleShowTimelineDialog, {
        once: true,
      });
    };
    window.addEventListener("scroll", handleShowTimelineDialog, { once: true });
    return () => {
      window.removeEventListener("scroll", handleShowTimelineDialog);
    };
  }, [calendar?.handle, isEventCard, openTimelineDialog, subscribed]);

  const onMailingListConfirm = async () => {
    // Close the dialog
    setShowMailingListDialog(false);
    // Proceed to show the add-to-calendar dialog
    setOpenAddToCalendarDialog(true);
  };

  const copyToClipboard = useCallback(() => {
    copyCalendarLinkToClipboard({
      inGroup,
      user,
      calendar,
      showSnackBar,
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
    });
  }, [
    calendar,
    inGroup,
    setSnackbarMessage,
    setSnackbarOpen,
    setSnackbarSeverity,
    showSnackBar,
    user,
  ]);

  useEffect(() => {
    const subscribing = searchParams.get("subscribing");
    if (subscribing && user) {
      const newSearchParams = new URLSearchParams(searchParams.toString());
      newSearchParams.delete("subscribing");
      setSearchParams(newSearchParams);
      const matchCalendar = calendar._id === JSON.parse(subscribing).calendarId;
      const matchEvent = eventId
        ? eventId === JSON.parse(subscribing).eventId
        : true;
      if (matchCalendar && matchEvent) {
        if (!subscribed) {
          // Check if user should be shown the mailing list dialog
          handleCheckShowMailingListDialog(calendar).then(
            (needsMailingListDialog) => {
              if (needsMailingListDialog) {
                return setShowMailingListDialog(true);
              }
              setOpenAddToCalendarDialog(true);
            }
          );
        } else {
          showSnackBar(
            setSnackbarOpen,
            setSnackbarMessage,
            setSnackbarSeverity,
            "You've already added this calendar.",
            "success"
          );
        }
      }
    }
  }, [calendar._id, searchParams, user]);

  const handleCheckShowMailingListDialog = useCallback(async (calendar) => {
    try {
      await axios.post(
        getApiRoute("auth", "CHECK_SHOW_MAILING_LIST_DIALOG"),
        { calendarId: calendar._id, handle: calendar?.handle },
        { withCredentials: true }
      );
      return false;
    } catch (error) {
      return true;
    }
  }, []);

  const handleSubscribe = useCallback(
    async (targetCalendar) => {
      try {
        if (typeGroup) {
          // calendar is a group
          await subscribeGroup({ groupId: targetCalendar._id });
        } else {
          await subscribeCalendar({
            calendarId: targetCalendar._id,
            handle: targetCalendar?.handle,
          });
        }
        onSubscribeAction?.(true);
        setTimeout(() => {
          showSnackBar(
            setSnackbarOpen,
            setSnackbarMessage,
            setSnackbarSeverity,
            <RedirectionMessage
              redirectionCallback={() =>
                handleOpenWebcal(getWebcalFormat(calendarApiUrl), calendar)
              }
            />,
            "success"
          );
        }, 1000);
        if (isEventCard) {
          setSubscribed(true);
        } else {
          setSelectedCalendar?.(targetCalendar);
        }
        refetchAuth();
      } catch (error) {
        if (error.response && error.response.status === 403) {
          // user doesn't have valid subscription
          return navigate(
            getPageRoute(
              "upgrade",
              "UPGRADE_PLUS",
              {},
              { forward: window.location.pathname + window.location.search }
            )
          );
        }
        console.error(error);
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          error.message || "An error occurred. Please try again.",
          "error"
        );
      }
    },
    [
      calendarApiUrl,
      handleOpenWebcal,
      isEventCard,
      navigate,
      onSubscribeAction,
      refetchAuth,
      setSelectedCalendar,
      setSnackbarMessage,
      setSnackbarOpen,
      setSnackbarSeverity,
      showSnackBar,
      subscribeCalendar,
      subscribeGroup,
      typeGroup,
    ]
  );

  const { mutateAsync: handleOpenAddToCalendar, isLoading: isLoadingOpenAddToCalendar } = useMutation(useCallback(async () => {
    const extraSearchParams = {
      subscribing: JSON.stringify({
        calendarId: calendar._id,
        handle: calendar?.handle,
        eventId,
      }),
    };
    if (!user) {
      // If it's a small screen, open the sign-in dialog
      if (isSmallScreen) {
        const newSearchParams = new URLSearchParams(window.location.search);
        for (const [key, value] of Object.entries(extraSearchParams)) {
          newSearchParams.set(key, value);
        }
        flushSync(() => setSearchParams(newSearchParams));
        window.history.replaceState({}, "", `?${newSearchParams.toString()}`);
        return setOpenSignInDialog(true);
      }
      // Otherwise, handle redirect for signed-out users
      if (redirectSignedOutUser(user, navigate, extraSearchParams)) return;
    }
    // At this point, the user is signed in
    // If it's a group type, handle subscription
    if (typeGroup) {
      return handleSubscribe(calendar);
    }
    // Check if user should be shown the mailing list dialog
    const needsMailingListDialog = await handleCheckShowMailingListDialog(
      calendar
    );
    if (needsMailingListDialog) {
      return setShowMailingListDialog(true);
    }
    // For non-group types or when typeGroup is not set, open the add-to-calendar dialog
    return setOpenAddToCalendarDialog(true);
  }, [
    calendar,
    eventId,
    handleCheckShowMailingListDialog,
    handleSubscribe,
    isSmallScreen,
    navigate,
    setSearchParams,
    typeGroup,
    user,
  ]));

  const calendarGroup = useMemo(() => {
    if (userCalendars?.createdGroups) {
      return userCalendars.createdGroups.find((group) =>
        group.calendars.some((cal) => cal._id === calendar._id)
      );
    }
  }, [calendar._id, userCalendars]);

  return (
    <>
      {!isEventCard &&
        (subscribed ? (
          <Box display="flex" flexDirection="column">
            <Stack direction="row" spacing={2}>
              {inGroup && calendarGroup ? (
                  <Button
                  data-testid="button-already-added"
                  variant="outlined"
                  size="shrinked"
                  sx={{ minWidth: "172px", maxWidth: "172px" }}
                  onClick={() => navigate(`/${calendarGroup.handle}/${calendarGroup._id}`)}
                >
                  <Icon baseClassName="material-symbols-outlined" fontSize="medium" sx={{ fontWeight: "300", color: `${theme.palette.icon.primary}`, mr: 0.75 }}>folder</Icon>
                  <Box as="span" sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{calendarGroup.name}</Box>
                  <Icon baseClassName="material-symbols-outlined" fontSize="medium" sx={{ fontWeight: "300", color: `${theme.palette.icon.primary}`, ml: 1 }}>north_east</Icon>
                </Button>
              ) : (
              <Button
                data-testid="button-already-added"
                variant="outlined"
                size="shrinked"
                sx={{ minWidth: "172px" }}
                disabled
              >
                Added
              </Button>
              )}
              <Tooltip title="Copy Link">
                <IconButton onClick={copyToClipboard} size="small">
                  <Icon
                    baseClassName="material-symbols-outlined"
                    fontSize="medium"
                    sx={{
                      color: `${theme.palette.icon.primary}`,
                      verticalAlign: "middle",
                    }}
                  >
                    link
                  </Icon>
                </IconButton>
              </Tooltip>
              <ShareDialog
                calendar={calendar}
                handle={creator.handle}
                as="IconButton"
              />
              <Tooltip title="Unsubscribe">
                <IconButton
                  onClick={() => {
                    handleUnsubscribe({
                      user,
                      calendar,
                      unsubscribeGroup,
                      refetchUserCalendars,
                      createdGroups: userCalendars?.createdGroups ?? [],
                      removeFromGroup,
                      unsubscribeCalendar,
                      onUnsubscribed: async () => {
                        setSubscribed(false);
                        onSubscribeAction?.(false);
                        refetchAuth();
                        refetchUserCalendars();
                      },
                      showSnackBar,
                      setSnackbarOpen,
                      setSnackbarMessage,
                      setSnackbarSeverity,
                      setSubscribed,
                    });
                  }}
                  size="small"
                  data-testid="button-remove-from-calendar"
                >
                  <Icon
                    baseClassName="material-symbols-outlined"
                    fontSize="medium"
                    sx={{
                      color: `${theme.palette.icon.primary}`,
                      verticalAlign: "middle",
                    }}
                  >
                    delete
                  </Icon>
                </IconButton>
              </Tooltip>
            </Stack>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ mt: 1.5 }}
            >
              Not seeing events in your calendar?{" "}
              <Link
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (inGroup && calendarGroup) {
                    const groupApiUrl = `${calendarGroup.stanzaLink}/${user?.id}.ics`;
                    handleOpenWebcal(getWebcalFormat(groupApiUrl), calendarGroup);
                  } else {
                    handleOpenWebcal(getWebcalFormat(calendarApiUrl), calendar);
                  }
                }}
              >
                Resubscribe
              </Link>
            </Typography>
          </Box>
        ) : (
          <Stack direction="row" spacing={2}>
            <LoadingButton
              data-testid="add-to-calendar-button"
              id="add-to-calendar-button"
              size="shrinked"
              variant="contained"
              loading={isLoadingOpenAddToCalendar}
              onClick={handleOpenAddToCalendar}
            >
              Add to Calendar
            </LoadingButton>
            <ShareDialog
              calendar={calendar}
              handle={creator.handle}
              as="IconButton"
            />
          </Stack>
        ))}

      {isEventCard &&
        (subscribed ? (
          <Stack direction="row" spacing={0}>
            <Button
              data-testid="button-already-added"
              variant="text"
              size="small"
              sx={{ fontSize: "10px !important", letterSpacing: "-0.6px" }}
              disabled
            >
            {inGroup && calendarGroup ? (
              <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}><Icon baseClassName="material-symbols-outlined" sx={{ fontWeight: "300", fontSize: "1rem", mr: 0.5 }}>folder</Icon> Added</Box> 
             ) : "Added"}
            </Button>
            <Tooltip title="Copy Link">
              <IconButton onClick={copyToClipboard} size="small">
                <Icon
                  baseClassName="material-symbols-outlined"
                  fontSize="small"
                  sx={{
                    color: `${theme.palette.icon.primary}`,
                    verticalAlign: "middle",
                  }}
                >
                  link
                </Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Resubscribe">
              <IconButton
                onClick={() => {
                  handleOpenWebcal(getWebcalFormat(calendarApiUrl), calendar);
                }}
                size="small"
              >
                <Icon
                  baseClassName="material-symbols-outlined"
                  fontSize="small"
                  sx={{
                    color: `${theme.palette.icon.primary}`,
                    verticalAlign: "middle",
                  }}
                >
                  event_repeat
                </Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Unsubscribe">
              <IconButton
                onClick={() => {
                  handleUnsubscribe({
                    user,
                    calendar,
                    unsubscribeGroup,
                    refetchUserCalendars,
                    createdGroups: userCalendars?.createdGroups ?? [],
                    removeFromGroup,
                    unsubscribeCalendar,
                    onUnsubscribed: async () => {
                      setSubscribed(false);
                      onSubscribeAction?.(false);
                      refetchAuth();
                      refetchUserCalendars();
                    },
                    showSnackBar,
                    setSnackbarOpen,
                    setSnackbarMessage,
                    setSnackbarSeverity,
                    setSubscribed,
                  });
                }}
                size="small"
                data-testid="button-remove-from-calendar"
                sx={{ ml: "4px" }}
              >
                <Icon
                  baseClassName="material-symbols-outlined"
                  fontSize="small"
                  sx={{
                    color: `${theme.palette.icon.primary}`,
                    verticalAlign: "middle",
                  }}
                >
                  delete
                </Icon>
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          <LoadingButton
            data-testid="add-to-calendar-button"
            id="add-to-calendar-button"
            size="small"
            variant="contained"
            loading={isLoadingOpenAddToCalendar}
            onClick={handleOpenAddToCalendar}
          >
            Add to Calendar
          </LoadingButton>
        ))}

      <NewGroupDialog
        open={openNewGroup}
        handleClose={() => setOpenNewGroup(false)}
        handleCreateGroup={handleCreateGroup}
        user={user}
        calendarId={targetCalendar._id}
        handleMessage={handleMessage}
      />
      <AddToGroupDialog
        createdGroups={createdGroups}
        open={openAddToGroup}
        handleClose={() => setOpenAddToGroup(false)}
        handleCreate={() => {
          setOpenAddToGroup(false);
          setOpenNewGroup(true);
        }}
        handleAdd={handleAddToGroup}
      />
      {user && (
        <ConsumerMailingListDialog
          open={showMailingListDialog}
          handleClose={() => setShowMailingListDialog(false)}
          creator={creator}
          user={user}
          onConfirm={onMailingListConfirm}
        />
      )}

      {!user && openSignInDialog && (
        <SignInDialog
          open={openSignInDialog}
          handleClose={() => setOpenSignInDialog(false)}
        />
      )}

      {user && openAddToCalendarDialog && (
        <AddToCalendarDialog
          open={openAddToCalendarDialog}
          handleClose={() => setOpenAddToCalendarDialog(false)}
          handleAddToCalendar={handleSubscribe}
          handleAddToGroup={handleGroupDialog}
          calendarId={calendar._id}
          handle={calendar.handle}
          initialCalendar={calendar}
          isEventCard={isEventCard}
        />
      )}

      {openTimelineDialog && (
        <TimelineDialog
          open={openTimelineDialog}
          handleClose={() => setOpenTimelineDialog(false)}
          handleAddToCalendar={handleSubscribe}
          calendarId={calendar._id}
          handle={calendar.handle}
          initialCalendar={calendar}
          handleOpenAddToCalendar={handleOpenAddToCalendar}
        />
      )}
    </>
  );
}

export default memo(SubscribeButton);
